import { default as add_write335cmMm2pVMeta } from "/app/src/client/pages/add_write.vue?macro=true";
import { default as content_45modifyCTlcdWx5a2Meta } from "/app/src/client/pages/admin/content-modify.vue?macro=true";
import { default as _91id_930yybRUGuE7Meta } from "/app/src/client/pages/admin/content/[id].vue?macro=true";
import { default as _91qnaid_93AFLSFDqoaaMeta } from "/app/src/client/pages/admin/content/[qnaid].vue?macro=true";
import { default as bannerDqm0w2jRtRMeta } from "/app/src/client/pages/admin/content/banner.vue?macro=true";
import { default as base_45board_45managementhSe4cICHK4Meta } from "/app/src/client/pages/admin/content/base-board-management.vue?macro=true";
import { default as board_45contentz53lkRPZ4uMeta } from "/app/src/client/pages/admin/content/board-content.vue?macro=true";
import { default as certificationFJS9t9B1v4Meta } from "/app/src/client/pages/admin/content/certification.vue?macro=true";
import { default as account_45modalR6twSSLZSDMeta } from "/app/src/client/pages/admin/content/component/account-modal.vue?macro=true";
import { default as banner_45order_45modaled2LJSSAUDMeta } from "/app/src/client/pages/admin/content/component/banner-order-modal.vue?macro=true";
import { default as base_45modalBA84YbnM03Meta } from "/app/src/client/pages/admin/content/component/base-modal.vue?macro=true";
import { default as base_45searchbarNJ00i6VdF2Meta } from "/app/src/client/pages/admin/content/component/base-searchbar.vue?macro=true";
import { default as board_45baseCESIrswwrRMeta } from "/app/src/client/pages/admin/content/component/board-base.vue?macro=true";
import { default as category_45modaloxih2rewceMeta } from "/app/src/client/pages/admin/content/component/category-modal.vue?macro=true";
import { default as popup_45order_45modal96ZhSa8HnRMeta } from "/app/src/client/pages/admin/content/component/popup-order-modal.vue?macro=true";
import { default as qna_45category_45modalCg9uVktsm8Meta } from "/app/src/client/pages/admin/content/component/qna-category-modal.vue?macro=true";
import { default as simple_45valid_45fieldhEZbfx6WoJMeta } from "/app/src/client/pages/admin/content/component/simple-valid-field.vue?macro=true";
import { default as user_45modify_45modalBk2tuuzvtxMeta } from "/app/src/client/pages/admin/content/component/user-modify-modal.vue?macro=true";
import { default as engineering4BXdoxLO1NMeta } from "/app/src/client/pages/admin/content/engineering.vue?macro=true";
import { default as indexj6Ra9ZFNZoMeta } from "/app/src/client/pages/admin/content/index.vue?macro=true";
import { default as newslettervS7RoG0I9EMeta } from "/app/src/client/pages/admin/content/newsletter.vue?macro=true";
import { default as noticehgWq2urkJZMeta } from "/app/src/client/pages/admin/content/notice.vue?macro=true";
import { default as popup_45modifyndwzAakOhnMeta } from "/app/src/client/pages/admin/content/popup-modify.vue?macro=true";
import { default as popup1pDpG02WkaMeta } from "/app/src/client/pages/admin/content/popup.vue?macro=true";
import { default as qnaygutKIyOdYMeta } from "/app/src/client/pages/admin/content/qna.vue?macro=true";
import { default as rndONy8xR1vayMeta } from "/app/src/client/pages/admin/content/rnd.vue?macro=true";
import { default as subscribe3XSnCH1FM8Meta } from "/app/src/client/pages/admin/content/subscribe.vue?macro=true";
import { default as userysfX7i5xb0Meta } from "/app/src/client/pages/admin/content/user.vue?macro=true";
import { default as content4kjYygfUeZMeta } from "/app/src/client/pages/admin/content.vue?macro=true";
import { default as indexklteqjQVTBMeta } from "/app/src/client/pages/admin/index.vue?macro=true";
import { default as footeruryZHDcLRYMeta } from "/app/src/client/pages/admin/layout/footer.vue?macro=true";
import { default as header4UiPftVQzRMeta } from "/app/src/client/pages/admin/layout/header.vue?macro=true";
import { default as sidebarUAK0bXTUWyMeta } from "/app/src/client/pages/admin/layout/sidebar.vue?macro=true";
import { default as preview_45pageYYmFrwsIFCMeta } from "/app/src/client/pages/admin/preview-page.vue?macro=true";
import { default as qna_45content_45modifyIoyPQq6Fm6Meta } from "/app/src/client/pages/admin/qna-content-modify.vue?macro=true";
import { default as searchbar8RBsZtJNXJMeta } from "/app/src/client/pages/admin/searchbar.vue?macro=true";
import { default as admin_loginaOsqYnkH7iMeta } from "/app/src/client/pages/admin_login.vue?macro=true";
import { default as carbon03b8jfdgHCTNMeta } from "/app/src/client/pages/carbon/carbon03.vue?macro=true";
import { default as certification01m63LNqpDnKMeta } from "/app/src/client/pages/certification/certification01.vue?macro=true";
import { default as certification02Etlx7oPjFBMeta } from "/app/src/client/pages/certification/certification02.vue?macro=true";
import { default as certification03o4xv77EJacMeta } from "/app/src/client/pages/certification/certification03.vue?macro=true";
import { default as certification04LANPjyqwtcMeta } from "/app/src/client/pages/certification/certification04.vue?macro=true";
import { default as certification05ICNF1cEFwHMeta } from "/app/src/client/pages/certification/certification05.vue?macro=true";
import { default as certification06viPSsv6OtxMeta } from "/app/src/client/pages/certification/certification06.vue?macro=true";
import { default as certification07ldDEgZIAEBMeta } from "/app/src/client/pages/certification/certification07.vue?macro=true";
import { default as aboutmQv6peXjtjMeta } from "/app/src/client/pages/company/about.vue?macro=true";
import { default as ci2LB7KpXdCtMeta } from "/app/src/client/pages/company/ci.vue?macro=true";
import { default as group5wsNjrAMs5Meta } from "/app/src/client/pages/company/group.vue?macro=true";
import { default as historyF3uwsKJ0PkMeta } from "/app/src/client/pages/company/history.vue?macro=true";
import { default as locationra7XCliXkLMeta } from "/app/src/client/pages/company/location.vue?macro=true";
import { default as diagnosis01rnwQJaFAJbMeta } from "/app/src/client/pages/diagnosis/diagnosis01.vue?macro=true";
import { default as diagnosis02i6KYLzArKgMeta } from "/app/src/client/pages/diagnosis/diagnosis02.vue?macro=true";
import { default as diagnosis03vz2RtImUYbMeta } from "/app/src/client/pages/diagnosis/diagnosis03.vue?macro=true";
import { default as diagnosis04twNcou7JNHMeta } from "/app/src/client/pages/diagnosis/diagnosis04.vue?macro=true";
import { default as diagnosis05XmsHiwaLt2Meta } from "/app/src/client/pages/diagnosis/diagnosis05.vue?macro=true";
import { default as diagnosis06ZHimVjzHTnMeta } from "/app/src/client/pages/diagnosis/diagnosis06.vue?macro=true";
import { default as indexKgX6wSpKKqMeta } from "/app/src/client/pages/index.vue?macro=true";
import { default as mainbBIo97gplRMeta } from "/app/src/client/pages/main.vue?macro=true";
import { default as _91id_93xWFTdYOTogMeta } from "/app/src/client/pages/media/[id].vue?macro=true";
import { default as _91qnaId_93Qs6XPibKBHMeta } from "/app/src/client/pages/media/[qnaId].vue?macro=true";
import { default as media01hkGfg6y3AcMeta } from "/app/src/client/pages/media/media01.vue?macro=true";
import { default as media02lDJMeBvHT9Meta } from "/app/src/client/pages/media/media02.vue?macro=true";
import { default as media03B4OG2yuva5Meta } from "/app/src/client/pages/media/media03.vue?macro=true";
import { default as media04Vo5CIu3XmDMeta } from "/app/src/client/pages/media/media04.vue?macro=true";
import { default as media_qna_inquiryCAVnWUeJQsMeta } from "/app/src/client/pages/media/media_qna_inquiry.vue?macro=true";
import { default as media_qna_viewrWxshz3ky6Meta } from "/app/src/client/pages/media/media_qna_view.vue?macro=true";
import { default as not_foundY4qMGYDgngMeta } from "/app/src/client/pages/not_found.vue?macro=true";
import { default as _91id_93klFaYdRwYKMeta } from "/app/src/client/pages/performance/[id].vue?macro=true";
import { default as performance01_listqw5H7GggNhMeta } from "/app/src/client/pages/performance/performance01_list.vue?macro=true";
import { default as performance02_listEp0pdHBTEuMeta } from "/app/src/client/pages/performance/performance02_list.vue?macro=true";
import { default as performance03_listjj730kWb2PMeta } from "/app/src/client/pages/performance/performance03_list.vue?macro=true";
import { default as performance_allCfthx2ESJuMeta } from "/app/src/client/pages/performance/performance_all.vue?macro=true";
import { default as performance_viewEmTR9W1TVtMeta } from "/app/src/client/pages/performance/performance_view.vue?macro=true";
import { default as policy01Ud7qZ79YFwMeta } from "/app/src/client/pages/policy/policy01.vue?macro=true";
import { default as policy02Gh6Tfup5iuMeta } from "/app/src/client/pages/policy/policy02.vue?macro=true";
import { default as policy03p7lSomtK6yMeta } from "/app/src/client/pages/policy/policy03.vue?macro=true";
import { default as _91id_93Xi18apHLajMeta } from "/app/src/client/pages/popup/[id].vue?macro=true";
import { default as indexQeK4vjyxXJMeta } from "/app/src/client/pages/popup/index.vue?macro=true";
import { default as listIGcBTJUIyMMeta } from "/app/src/client/pages/popup/list.vue?macro=true";
import { default as popup_modalSI5M1IeZwFMeta } from "/app/src/client/pages/popup_modal.vue?macro=true";
import { default as qna_write2RPwnzO49XMeta } from "/app/src/client/pages/qna_write.vue?macro=true";
import { default as rnd01BCxScTBYVYMeta } from "/app/src/client/pages/rnd/rnd01.vue?macro=true";
import { default as rnd02YPabRHyC0eMeta } from "/app/src/client/pages/rnd/rnd02.vue?macro=true";
import { default as rnd03Z0u7QcYdGBMeta } from "/app/src/client/pages/rnd/rnd03.vue?macro=true";
import { default as rnd04rYx09XeYiSMeta } from "/app/src/client/pages/rnd/rnd04.vue?macro=true";
export default [
  {
    name: add_write335cmMm2pVMeta?.name ?? "add_write",
    path: add_write335cmMm2pVMeta?.path ?? "/add_write",
    meta: add_write335cmMm2pVMeta || {},
    alias: add_write335cmMm2pVMeta?.alias || [],
    redirect: add_write335cmMm2pVMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/add_write.vue").then(m => m.default || m)
  },
  {
    name: content_45modifyCTlcdWx5a2Meta?.name ?? "admin-content-modify",
    path: content_45modifyCTlcdWx5a2Meta?.path ?? "/admin/content-modify",
    meta: content_45modifyCTlcdWx5a2Meta || {},
    alias: content_45modifyCTlcdWx5a2Meta?.alias || [],
    redirect: content_45modifyCTlcdWx5a2Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content-modify.vue").then(m => m.default || m)
  },
  {
    path: content4kjYygfUeZMeta?.path ?? "/admin/content",
    children: [
  {
    name: _91id_930yybRUGuE7Meta?.name ?? "admin-content-id",
    path: _91id_930yybRUGuE7Meta?.path ?? ":id",
    meta: _91id_930yybRUGuE7Meta || {},
    alias: _91id_930yybRUGuE7Meta?.alias || [],
    redirect: _91id_930yybRUGuE7Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/[id].vue").then(m => m.default || m)
  },
  {
    name: _91qnaid_93AFLSFDqoaaMeta?.name ?? "admin-content-qnaid",
    path: _91qnaid_93AFLSFDqoaaMeta?.path ?? ":qnaid",
    meta: _91qnaid_93AFLSFDqoaaMeta || {},
    alias: _91qnaid_93AFLSFDqoaaMeta?.alias || [],
    redirect: _91qnaid_93AFLSFDqoaaMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/[qnaid].vue").then(m => m.default || m)
  },
  {
    name: bannerDqm0w2jRtRMeta?.name ?? "admin-content-banner",
    path: bannerDqm0w2jRtRMeta?.path ?? "banner",
    meta: bannerDqm0w2jRtRMeta || {},
    alias: bannerDqm0w2jRtRMeta?.alias || [],
    redirect: bannerDqm0w2jRtRMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/banner.vue").then(m => m.default || m)
  },
  {
    name: base_45board_45managementhSe4cICHK4Meta?.name ?? "admin-content-base-board-management",
    path: base_45board_45managementhSe4cICHK4Meta?.path ?? "base-board-management",
    meta: base_45board_45managementhSe4cICHK4Meta || {},
    alias: base_45board_45managementhSe4cICHK4Meta?.alias || [],
    redirect: base_45board_45managementhSe4cICHK4Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/base-board-management.vue").then(m => m.default || m)
  },
  {
    name: board_45contentz53lkRPZ4uMeta?.name ?? "admin-content-board-content",
    path: board_45contentz53lkRPZ4uMeta?.path ?? "board-content",
    meta: board_45contentz53lkRPZ4uMeta || {},
    alias: board_45contentz53lkRPZ4uMeta?.alias || [],
    redirect: board_45contentz53lkRPZ4uMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/board-content.vue").then(m => m.default || m)
  },
  {
    name: certificationFJS9t9B1v4Meta?.name ?? "admin-content-certification",
    path: certificationFJS9t9B1v4Meta?.path ?? "certification",
    meta: certificationFJS9t9B1v4Meta || {},
    alias: certificationFJS9t9B1v4Meta?.alias || [],
    redirect: certificationFJS9t9B1v4Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/certification.vue").then(m => m.default || m)
  },
  {
    name: account_45modalR6twSSLZSDMeta?.name ?? "admin-content-component-account-modal",
    path: account_45modalR6twSSLZSDMeta?.path ?? "component/account-modal",
    meta: account_45modalR6twSSLZSDMeta || {},
    alias: account_45modalR6twSSLZSDMeta?.alias || [],
    redirect: account_45modalR6twSSLZSDMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/account-modal.vue").then(m => m.default || m)
  },
  {
    name: banner_45order_45modaled2LJSSAUDMeta?.name ?? "admin-content-component-banner-order-modal",
    path: banner_45order_45modaled2LJSSAUDMeta?.path ?? "component/banner-order-modal",
    meta: banner_45order_45modaled2LJSSAUDMeta || {},
    alias: banner_45order_45modaled2LJSSAUDMeta?.alias || [],
    redirect: banner_45order_45modaled2LJSSAUDMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/banner-order-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45modalBA84YbnM03Meta?.name ?? "admin-content-component-base-modal",
    path: base_45modalBA84YbnM03Meta?.path ?? "component/base-modal",
    meta: base_45modalBA84YbnM03Meta || {},
    alias: base_45modalBA84YbnM03Meta?.alias || [],
    redirect: base_45modalBA84YbnM03Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45searchbarNJ00i6VdF2Meta?.name ?? "admin-content-component-base-searchbar",
    path: base_45searchbarNJ00i6VdF2Meta?.path ?? "component/base-searchbar",
    meta: base_45searchbarNJ00i6VdF2Meta || {},
    alias: base_45searchbarNJ00i6VdF2Meta?.alias || [],
    redirect: base_45searchbarNJ00i6VdF2Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/base-searchbar.vue").then(m => m.default || m)
  },
  {
    name: board_45baseCESIrswwrRMeta?.name ?? "admin-content-component-board-base",
    path: board_45baseCESIrswwrRMeta?.path ?? "component/board-base",
    meta: board_45baseCESIrswwrRMeta || {},
    alias: board_45baseCESIrswwrRMeta?.alias || [],
    redirect: board_45baseCESIrswwrRMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/board-base.vue").then(m => m.default || m)
  },
  {
    name: category_45modaloxih2rewceMeta?.name ?? "admin-content-component-category-modal",
    path: category_45modaloxih2rewceMeta?.path ?? "component/category-modal",
    meta: category_45modaloxih2rewceMeta || {},
    alias: category_45modaloxih2rewceMeta?.alias || [],
    redirect: category_45modaloxih2rewceMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/category-modal.vue").then(m => m.default || m)
  },
  {
    name: popup_45order_45modal96ZhSa8HnRMeta?.name ?? "admin-content-component-popup-order-modal",
    path: popup_45order_45modal96ZhSa8HnRMeta?.path ?? "component/popup-order-modal",
    meta: popup_45order_45modal96ZhSa8HnRMeta || {},
    alias: popup_45order_45modal96ZhSa8HnRMeta?.alias || [],
    redirect: popup_45order_45modal96ZhSa8HnRMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/popup-order-modal.vue").then(m => m.default || m)
  },
  {
    name: qna_45category_45modalCg9uVktsm8Meta?.name ?? "admin-content-component-qna-category-modal",
    path: qna_45category_45modalCg9uVktsm8Meta?.path ?? "component/qna-category-modal",
    meta: qna_45category_45modalCg9uVktsm8Meta || {},
    alias: qna_45category_45modalCg9uVktsm8Meta?.alias || [],
    redirect: qna_45category_45modalCg9uVktsm8Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/qna-category-modal.vue").then(m => m.default || m)
  },
  {
    name: simple_45valid_45fieldhEZbfx6WoJMeta?.name ?? "admin-content-component-simple-valid-field",
    path: simple_45valid_45fieldhEZbfx6WoJMeta?.path ?? "component/simple-valid-field",
    meta: simple_45valid_45fieldhEZbfx6WoJMeta || {},
    alias: simple_45valid_45fieldhEZbfx6WoJMeta?.alias || [],
    redirect: simple_45valid_45fieldhEZbfx6WoJMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/simple-valid-field.vue").then(m => m.default || m)
  },
  {
    name: user_45modify_45modalBk2tuuzvtxMeta?.name ?? "admin-content-component-user-modify-modal",
    path: user_45modify_45modalBk2tuuzvtxMeta?.path ?? "component/user-modify-modal",
    meta: user_45modify_45modalBk2tuuzvtxMeta || {},
    alias: user_45modify_45modalBk2tuuzvtxMeta?.alias || [],
    redirect: user_45modify_45modalBk2tuuzvtxMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/component/user-modify-modal.vue").then(m => m.default || m)
  },
  {
    name: engineering4BXdoxLO1NMeta?.name ?? "admin-content-engineering",
    path: engineering4BXdoxLO1NMeta?.path ?? "engineering",
    meta: engineering4BXdoxLO1NMeta || {},
    alias: engineering4BXdoxLO1NMeta?.alias || [],
    redirect: engineering4BXdoxLO1NMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/engineering.vue").then(m => m.default || m)
  },
  {
    name: indexj6Ra9ZFNZoMeta?.name ?? "admin-content",
    path: indexj6Ra9ZFNZoMeta?.path ?? "",
    meta: indexj6Ra9ZFNZoMeta || {},
    alias: indexj6Ra9ZFNZoMeta?.alias || [],
    redirect: indexj6Ra9ZFNZoMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/index.vue").then(m => m.default || m)
  },
  {
    name: newslettervS7RoG0I9EMeta?.name ?? "admin-content-newsletter",
    path: newslettervS7RoG0I9EMeta?.path ?? "newsletter",
    meta: newslettervS7RoG0I9EMeta || {},
    alias: newslettervS7RoG0I9EMeta?.alias || [],
    redirect: newslettervS7RoG0I9EMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/newsletter.vue").then(m => m.default || m)
  },
  {
    name: noticehgWq2urkJZMeta?.name ?? "admin-content-notice",
    path: noticehgWq2urkJZMeta?.path ?? "notice",
    meta: noticehgWq2urkJZMeta || {},
    alias: noticehgWq2urkJZMeta?.alias || [],
    redirect: noticehgWq2urkJZMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/notice.vue").then(m => m.default || m)
  },
  {
    name: popup_45modifyndwzAakOhnMeta?.name ?? "admin-content-popup-modify",
    path: popup_45modifyndwzAakOhnMeta?.path ?? "popup-modify",
    meta: popup_45modifyndwzAakOhnMeta || {},
    alias: popup_45modifyndwzAakOhnMeta?.alias || [],
    redirect: popup_45modifyndwzAakOhnMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/popup-modify.vue").then(m => m.default || m)
  },
  {
    name: popup1pDpG02WkaMeta?.name ?? "admin-content-popup",
    path: popup1pDpG02WkaMeta?.path ?? "popup",
    meta: popup1pDpG02WkaMeta || {},
    alias: popup1pDpG02WkaMeta?.alias || [],
    redirect: popup1pDpG02WkaMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/popup.vue").then(m => m.default || m)
  },
  {
    name: qnaygutKIyOdYMeta?.name ?? "admin-content-qna",
    path: qnaygutKIyOdYMeta?.path ?? "qna",
    meta: qnaygutKIyOdYMeta || {},
    alias: qnaygutKIyOdYMeta?.alias || [],
    redirect: qnaygutKIyOdYMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/qna.vue").then(m => m.default || m)
  },
  {
    name: rndONy8xR1vayMeta?.name ?? "admin-content-rnd",
    path: rndONy8xR1vayMeta?.path ?? "rnd",
    meta: rndONy8xR1vayMeta || {},
    alias: rndONy8xR1vayMeta?.alias || [],
    redirect: rndONy8xR1vayMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/rnd.vue").then(m => m.default || m)
  },
  {
    name: subscribe3XSnCH1FM8Meta?.name ?? "admin-content-subscribe",
    path: subscribe3XSnCH1FM8Meta?.path ?? "subscribe",
    meta: subscribe3XSnCH1FM8Meta || {},
    alias: subscribe3XSnCH1FM8Meta?.alias || [],
    redirect: subscribe3XSnCH1FM8Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/subscribe.vue").then(m => m.default || m)
  },
  {
    name: userysfX7i5xb0Meta?.name ?? "admin-content-user",
    path: userysfX7i5xb0Meta?.path ?? "user",
    meta: userysfX7i5xb0Meta || {},
    alias: userysfX7i5xb0Meta?.alias || [],
    redirect: userysfX7i5xb0Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content/user.vue").then(m => m.default || m)
  }
],
    name: content4kjYygfUeZMeta?.name ?? undefined,
    meta: content4kjYygfUeZMeta || {},
    alias: content4kjYygfUeZMeta?.alias || [],
    redirect: content4kjYygfUeZMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/content.vue").then(m => m.default || m)
  },
  {
    name: indexklteqjQVTBMeta?.name ?? "admin",
    path: indexklteqjQVTBMeta?.path ?? "/admin",
    meta: indexklteqjQVTBMeta || {},
    alias: indexklteqjQVTBMeta?.alias || [],
    redirect: indexklteqjQVTBMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: footeruryZHDcLRYMeta?.name ?? "admin-layout-footer",
    path: footeruryZHDcLRYMeta?.path ?? "/admin/layout/footer",
    meta: footeruryZHDcLRYMeta || {},
    alias: footeruryZHDcLRYMeta?.alias || [],
    redirect: footeruryZHDcLRYMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/layout/footer.vue").then(m => m.default || m)
  },
  {
    name: header4UiPftVQzRMeta?.name ?? "admin-layout-header",
    path: header4UiPftVQzRMeta?.path ?? "/admin/layout/header",
    meta: header4UiPftVQzRMeta || {},
    alias: header4UiPftVQzRMeta?.alias || [],
    redirect: header4UiPftVQzRMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/layout/header.vue").then(m => m.default || m)
  },
  {
    name: sidebarUAK0bXTUWyMeta?.name ?? "admin-layout-sidebar",
    path: sidebarUAK0bXTUWyMeta?.path ?? "/admin/layout/sidebar",
    meta: sidebarUAK0bXTUWyMeta || {},
    alias: sidebarUAK0bXTUWyMeta?.alias || [],
    redirect: sidebarUAK0bXTUWyMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/layout/sidebar.vue").then(m => m.default || m)
  },
  {
    name: preview_45pageYYmFrwsIFCMeta?.name ?? "admin-preview-page",
    path: preview_45pageYYmFrwsIFCMeta?.path ?? "/admin/preview-page",
    meta: preview_45pageYYmFrwsIFCMeta || {},
    alias: preview_45pageYYmFrwsIFCMeta?.alias || [],
    redirect: preview_45pageYYmFrwsIFCMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/preview-page.vue").then(m => m.default || m)
  },
  {
    name: qna_45content_45modifyIoyPQq6Fm6Meta?.name ?? "admin-qna-content-modify",
    path: qna_45content_45modifyIoyPQq6Fm6Meta?.path ?? "/admin/qna-content-modify",
    meta: qna_45content_45modifyIoyPQq6Fm6Meta || {},
    alias: qna_45content_45modifyIoyPQq6Fm6Meta?.alias || [],
    redirect: qna_45content_45modifyIoyPQq6Fm6Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/qna-content-modify.vue").then(m => m.default || m)
  },
  {
    name: searchbar8RBsZtJNXJMeta?.name ?? "admin-searchbar",
    path: searchbar8RBsZtJNXJMeta?.path ?? "/admin/searchbar",
    meta: searchbar8RBsZtJNXJMeta || {},
    alias: searchbar8RBsZtJNXJMeta?.alias || [],
    redirect: searchbar8RBsZtJNXJMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin/searchbar.vue").then(m => m.default || m)
  },
  {
    name: admin_loginaOsqYnkH7iMeta?.name ?? "admin_login",
    path: admin_loginaOsqYnkH7iMeta?.path ?? "/admin_login",
    meta: admin_loginaOsqYnkH7iMeta || {},
    alias: admin_loginaOsqYnkH7iMeta?.alias || [],
    redirect: admin_loginaOsqYnkH7iMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/admin_login.vue").then(m => m.default || m)
  },
  {
    name: carbon03b8jfdgHCTNMeta?.name ?? "carbon-carbon03",
    path: carbon03b8jfdgHCTNMeta?.path ?? "/carbon/carbon03",
    meta: carbon03b8jfdgHCTNMeta || {},
    alias: carbon03b8jfdgHCTNMeta?.alias || [],
    redirect: carbon03b8jfdgHCTNMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/carbon/carbon03.vue").then(m => m.default || m)
  },
  {
    name: certification01m63LNqpDnKMeta?.name ?? "certification-certification01",
    path: certification01m63LNqpDnKMeta?.path ?? "/certification/certification01",
    meta: certification01m63LNqpDnKMeta || {},
    alias: certification01m63LNqpDnKMeta?.alias || [],
    redirect: certification01m63LNqpDnKMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification01.vue").then(m => m.default || m)
  },
  {
    name: certification02Etlx7oPjFBMeta?.name ?? "certification-certification02",
    path: certification02Etlx7oPjFBMeta?.path ?? "/certification/certification02",
    meta: certification02Etlx7oPjFBMeta || {},
    alias: certification02Etlx7oPjFBMeta?.alias || [],
    redirect: certification02Etlx7oPjFBMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification02.vue").then(m => m.default || m)
  },
  {
    name: certification03o4xv77EJacMeta?.name ?? "certification-certification03",
    path: certification03o4xv77EJacMeta?.path ?? "/certification/certification03",
    meta: certification03o4xv77EJacMeta || {},
    alias: certification03o4xv77EJacMeta?.alias || [],
    redirect: certification03o4xv77EJacMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification03.vue").then(m => m.default || m)
  },
  {
    name: certification04LANPjyqwtcMeta?.name ?? "certification-certification04",
    path: certification04LANPjyqwtcMeta?.path ?? "/certification/certification04",
    meta: certification04LANPjyqwtcMeta || {},
    alias: certification04LANPjyqwtcMeta?.alias || [],
    redirect: certification04LANPjyqwtcMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification04.vue").then(m => m.default || m)
  },
  {
    name: certification05ICNF1cEFwHMeta?.name ?? "certification-certification05",
    path: certification05ICNF1cEFwHMeta?.path ?? "/certification/certification05",
    meta: certification05ICNF1cEFwHMeta || {},
    alias: certification05ICNF1cEFwHMeta?.alias || [],
    redirect: certification05ICNF1cEFwHMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification05.vue").then(m => m.default || m)
  },
  {
    name: certification06viPSsv6OtxMeta?.name ?? "certification-certification06",
    path: certification06viPSsv6OtxMeta?.path ?? "/certification/certification06",
    meta: certification06viPSsv6OtxMeta || {},
    alias: certification06viPSsv6OtxMeta?.alias || [],
    redirect: certification06viPSsv6OtxMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification06.vue").then(m => m.default || m)
  },
  {
    name: certification07ldDEgZIAEBMeta?.name ?? "certification-certification07",
    path: certification07ldDEgZIAEBMeta?.path ?? "/certification/certification07",
    meta: certification07ldDEgZIAEBMeta || {},
    alias: certification07ldDEgZIAEBMeta?.alias || [],
    redirect: certification07ldDEgZIAEBMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/certification/certification07.vue").then(m => m.default || m)
  },
  {
    name: aboutmQv6peXjtjMeta?.name ?? "company-about",
    path: aboutmQv6peXjtjMeta?.path ?? "/company/about",
    meta: aboutmQv6peXjtjMeta || {},
    alias: aboutmQv6peXjtjMeta?.alias || [],
    redirect: aboutmQv6peXjtjMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: ci2LB7KpXdCtMeta?.name ?? "company-ci",
    path: ci2LB7KpXdCtMeta?.path ?? "/company/ci",
    meta: ci2LB7KpXdCtMeta || {},
    alias: ci2LB7KpXdCtMeta?.alias || [],
    redirect: ci2LB7KpXdCtMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/company/ci.vue").then(m => m.default || m)
  },
  {
    name: group5wsNjrAMs5Meta?.name ?? "company-group",
    path: group5wsNjrAMs5Meta?.path ?? "/company/group",
    meta: group5wsNjrAMs5Meta || {},
    alias: group5wsNjrAMs5Meta?.alias || [],
    redirect: group5wsNjrAMs5Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/company/group.vue").then(m => m.default || m)
  },
  {
    name: historyF3uwsKJ0PkMeta?.name ?? "company-history",
    path: historyF3uwsKJ0PkMeta?.path ?? "/company/history",
    meta: historyF3uwsKJ0PkMeta || {},
    alias: historyF3uwsKJ0PkMeta?.alias || [],
    redirect: historyF3uwsKJ0PkMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/company/history.vue").then(m => m.default || m)
  },
  {
    name: locationra7XCliXkLMeta?.name ?? "company-location",
    path: locationra7XCliXkLMeta?.path ?? "/company/location",
    meta: locationra7XCliXkLMeta || {},
    alias: locationra7XCliXkLMeta?.alias || [],
    redirect: locationra7XCliXkLMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/company/location.vue").then(m => m.default || m)
  },
  {
    name: diagnosis01rnwQJaFAJbMeta?.name ?? "diagnosis-diagnosis01",
    path: diagnosis01rnwQJaFAJbMeta?.path ?? "/diagnosis/diagnosis01",
    meta: diagnosis01rnwQJaFAJbMeta || {},
    alias: diagnosis01rnwQJaFAJbMeta?.alias || [],
    redirect: diagnosis01rnwQJaFAJbMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis01.vue").then(m => m.default || m)
  },
  {
    name: diagnosis02i6KYLzArKgMeta?.name ?? "diagnosis-diagnosis02",
    path: diagnosis02i6KYLzArKgMeta?.path ?? "/diagnosis/diagnosis02",
    meta: diagnosis02i6KYLzArKgMeta || {},
    alias: diagnosis02i6KYLzArKgMeta?.alias || [],
    redirect: diagnosis02i6KYLzArKgMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis02.vue").then(m => m.default || m)
  },
  {
    name: diagnosis03vz2RtImUYbMeta?.name ?? "diagnosis-diagnosis03",
    path: diagnosis03vz2RtImUYbMeta?.path ?? "/diagnosis/diagnosis03",
    meta: diagnosis03vz2RtImUYbMeta || {},
    alias: diagnosis03vz2RtImUYbMeta?.alias || [],
    redirect: diagnosis03vz2RtImUYbMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis03.vue").then(m => m.default || m)
  },
  {
    name: diagnosis04twNcou7JNHMeta?.name ?? "diagnosis-diagnosis04",
    path: diagnosis04twNcou7JNHMeta?.path ?? "/diagnosis/diagnosis04",
    meta: diagnosis04twNcou7JNHMeta || {},
    alias: diagnosis04twNcou7JNHMeta?.alias || [],
    redirect: diagnosis04twNcou7JNHMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis04.vue").then(m => m.default || m)
  },
  {
    name: diagnosis05XmsHiwaLt2Meta?.name ?? "diagnosis-diagnosis05",
    path: diagnosis05XmsHiwaLt2Meta?.path ?? "/diagnosis/diagnosis05",
    meta: diagnosis05XmsHiwaLt2Meta || {},
    alias: diagnosis05XmsHiwaLt2Meta?.alias || [],
    redirect: diagnosis05XmsHiwaLt2Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis05.vue").then(m => m.default || m)
  },
  {
    name: diagnosis06ZHimVjzHTnMeta?.name ?? "diagnosis-diagnosis06",
    path: diagnosis06ZHimVjzHTnMeta?.path ?? "/diagnosis/diagnosis06",
    meta: diagnosis06ZHimVjzHTnMeta || {},
    alias: diagnosis06ZHimVjzHTnMeta?.alias || [],
    redirect: diagnosis06ZHimVjzHTnMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/diagnosis/diagnosis06.vue").then(m => m.default || m)
  },
  {
    name: indexKgX6wSpKKqMeta?.name ?? "index",
    path: indexKgX6wSpKKqMeta?.path ?? "/",
    meta: indexKgX6wSpKKqMeta || {},
    alias: indexKgX6wSpKKqMeta?.alias || [],
    redirect: indexKgX6wSpKKqMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mainbBIo97gplRMeta?.name ?? "main",
    path: mainbBIo97gplRMeta?.path ?? "/main",
    meta: mainbBIo97gplRMeta || {},
    alias: mainbBIo97gplRMeta?.alias || [],
    redirect: mainbBIo97gplRMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/main.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xWFTdYOTogMeta?.name ?? "media-id",
    path: _91id_93xWFTdYOTogMeta?.path ?? "/media/:id",
    meta: _91id_93xWFTdYOTogMeta || {},
    alias: _91id_93xWFTdYOTogMeta?.alias || [],
    redirect: _91id_93xWFTdYOTogMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/[id].vue").then(m => m.default || m)
  },
  {
    name: _91qnaId_93Qs6XPibKBHMeta?.name ?? "media-qnaId",
    path: _91qnaId_93Qs6XPibKBHMeta?.path ?? "/media/:qnaId",
    meta: _91qnaId_93Qs6XPibKBHMeta || {},
    alias: _91qnaId_93Qs6XPibKBHMeta?.alias || [],
    redirect: _91qnaId_93Qs6XPibKBHMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/[qnaId].vue").then(m => m.default || m)
  },
  {
    name: media01hkGfg6y3AcMeta?.name ?? "media-media01",
    path: media01hkGfg6y3AcMeta?.path ?? "/media/media01",
    meta: media01hkGfg6y3AcMeta || {},
    alias: media01hkGfg6y3AcMeta?.alias || [],
    redirect: media01hkGfg6y3AcMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media01.vue").then(m => m.default || m)
  },
  {
    name: media02lDJMeBvHT9Meta?.name ?? "media-media02",
    path: media02lDJMeBvHT9Meta?.path ?? "/media/media02",
    meta: media02lDJMeBvHT9Meta || {},
    alias: media02lDJMeBvHT9Meta?.alias || [],
    redirect: media02lDJMeBvHT9Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media02.vue").then(m => m.default || m)
  },
  {
    name: media03B4OG2yuva5Meta?.name ?? "media-media03",
    path: media03B4OG2yuva5Meta?.path ?? "/media/media03",
    meta: media03B4OG2yuva5Meta || {},
    alias: media03B4OG2yuva5Meta?.alias || [],
    redirect: media03B4OG2yuva5Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media03.vue").then(m => m.default || m)
  },
  {
    name: media04Vo5CIu3XmDMeta?.name ?? "media-media04",
    path: media04Vo5CIu3XmDMeta?.path ?? "/media/media04",
    meta: media04Vo5CIu3XmDMeta || {},
    alias: media04Vo5CIu3XmDMeta?.alias || [],
    redirect: media04Vo5CIu3XmDMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media04.vue").then(m => m.default || m)
  },
  {
    name: media_qna_inquiryCAVnWUeJQsMeta?.name ?? "media-media_qna_inquiry",
    path: media_qna_inquiryCAVnWUeJQsMeta?.path ?? "/media/media_qna_inquiry",
    meta: media_qna_inquiryCAVnWUeJQsMeta || {},
    alias: media_qna_inquiryCAVnWUeJQsMeta?.alias || [],
    redirect: media_qna_inquiryCAVnWUeJQsMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media_qna_inquiry.vue").then(m => m.default || m)
  },
  {
    name: media_qna_viewrWxshz3ky6Meta?.name ?? "media-media_qna_view",
    path: media_qna_viewrWxshz3ky6Meta?.path ?? "/media/media_qna_view",
    meta: media_qna_viewrWxshz3ky6Meta || {},
    alias: media_qna_viewrWxshz3ky6Meta?.alias || [],
    redirect: media_qna_viewrWxshz3ky6Meta?.redirect || undefined,
    component: () => import("/app/src/client/pages/media/media_qna_view.vue").then(m => m.default || m)
  },
  {
    name: not_foundY4qMGYDgngMeta?.name ?? "not_found",
    path: not_foundY4qMGYDgngMeta?.path ?? "/not_found",
    meta: not_foundY4qMGYDgngMeta || {},
    alias: not_foundY4qMGYDgngMeta?.alias || [],
    redirect: not_foundY4qMGYDgngMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/not_found.vue").then(m => m.default || m)
  },
  {
    name: _91id_93klFaYdRwYKMeta?.name ?? "performance-id",
    path: _91id_93klFaYdRwYKMeta?.path ?? "/performance/:id",
    meta: _91id_93klFaYdRwYKMeta || {},
    alias: _91id_93klFaYdRwYKMeta?.alias || [],
    redirect: _91id_93klFaYdRwYKMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/[id].vue").then(m => m.default || m)
  },
  {
    name: performance01_listqw5H7GggNhMeta?.name ?? "performance-performance01_list",
    path: performance01_listqw5H7GggNhMeta?.path ?? "/performance/performance01_list",
    meta: performance01_listqw5H7GggNhMeta || {},
    alias: performance01_listqw5H7GggNhMeta?.alias || [],
    redirect: performance01_listqw5H7GggNhMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/performance01_list.vue").then(m => m.default || m)
  },
  {
    name: performance02_listEp0pdHBTEuMeta?.name ?? "performance-performance02_list",
    path: performance02_listEp0pdHBTEuMeta?.path ?? "/performance/performance02_list",
    meta: performance02_listEp0pdHBTEuMeta || {},
    alias: performance02_listEp0pdHBTEuMeta?.alias || [],
    redirect: performance02_listEp0pdHBTEuMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/performance02_list.vue").then(m => m.default || m)
  },
  {
    name: performance03_listjj730kWb2PMeta?.name ?? "performance-performance03_list",
    path: performance03_listjj730kWb2PMeta?.path ?? "/performance/performance03_list",
    meta: performance03_listjj730kWb2PMeta || {},
    alias: performance03_listjj730kWb2PMeta?.alias || [],
    redirect: performance03_listjj730kWb2PMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/performance03_list.vue").then(m => m.default || m)
  },
  {
    name: performance_allCfthx2ESJuMeta?.name ?? "performance-performance_all",
    path: performance_allCfthx2ESJuMeta?.path ?? "/performance/performance_all",
    meta: performance_allCfthx2ESJuMeta || {},
    alias: performance_allCfthx2ESJuMeta?.alias || [],
    redirect: performance_allCfthx2ESJuMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/performance_all.vue").then(m => m.default || m)
  },
  {
    name: performance_viewEmTR9W1TVtMeta?.name ?? "performance-performance_view",
    path: performance_viewEmTR9W1TVtMeta?.path ?? "/performance/performance_view",
    meta: performance_viewEmTR9W1TVtMeta || {},
    alias: performance_viewEmTR9W1TVtMeta?.alias || [],
    redirect: performance_viewEmTR9W1TVtMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/performance/performance_view.vue").then(m => m.default || m)
  },
  {
    name: policy01Ud7qZ79YFwMeta?.name ?? "policy-policy01",
    path: policy01Ud7qZ79YFwMeta?.path ?? "/policy/policy01",
    meta: policy01Ud7qZ79YFwMeta || {},
    alias: policy01Ud7qZ79YFwMeta?.alias || [],
    redirect: policy01Ud7qZ79YFwMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/policy/policy01.vue").then(m => m.default || m)
  },
  {
    name: policy02Gh6Tfup5iuMeta?.name ?? "policy-policy02",
    path: policy02Gh6Tfup5iuMeta?.path ?? "/policy/policy02",
    meta: policy02Gh6Tfup5iuMeta || {},
    alias: policy02Gh6Tfup5iuMeta?.alias || [],
    redirect: policy02Gh6Tfup5iuMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/policy/policy02.vue").then(m => m.default || m)
  },
  {
    name: policy03p7lSomtK6yMeta?.name ?? "policy-policy03",
    path: policy03p7lSomtK6yMeta?.path ?? "/policy/policy03",
    meta: policy03p7lSomtK6yMeta || {},
    alias: policy03p7lSomtK6yMeta?.alias || [],
    redirect: policy03p7lSomtK6yMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/policy/policy03.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Xi18apHLajMeta?.name ?? "popup-id",
    path: _91id_93Xi18apHLajMeta?.path ?? "/popup/:id",
    meta: _91id_93Xi18apHLajMeta || {},
    alias: _91id_93Xi18apHLajMeta?.alias || [],
    redirect: _91id_93Xi18apHLajMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/popup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQeK4vjyxXJMeta?.name ?? "popup",
    path: indexQeK4vjyxXJMeta?.path ?? "/popup",
    meta: indexQeK4vjyxXJMeta || {},
    alias: indexQeK4vjyxXJMeta?.alias || [],
    redirect: indexQeK4vjyxXJMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/popup/index.vue").then(m => m.default || m)
  },
  {
    name: listIGcBTJUIyMMeta?.name ?? "popup-list",
    path: listIGcBTJUIyMMeta?.path ?? "/popup/list",
    meta: listIGcBTJUIyMMeta || {},
    alias: listIGcBTJUIyMMeta?.alias || [],
    redirect: listIGcBTJUIyMMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/popup/list.vue").then(m => m.default || m)
  },
  {
    name: popup_modalSI5M1IeZwFMeta?.name ?? "popup_modal",
    path: popup_modalSI5M1IeZwFMeta?.path ?? "/popup_modal",
    meta: popup_modalSI5M1IeZwFMeta || {},
    alias: popup_modalSI5M1IeZwFMeta?.alias || [],
    redirect: popup_modalSI5M1IeZwFMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/popup_modal.vue").then(m => m.default || m)
  },
  {
    name: qna_write2RPwnzO49XMeta?.name ?? "qna_write",
    path: qna_write2RPwnzO49XMeta?.path ?? "/qna_write",
    meta: qna_write2RPwnzO49XMeta || {},
    alias: qna_write2RPwnzO49XMeta?.alias || [],
    redirect: qna_write2RPwnzO49XMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/qna_write.vue").then(m => m.default || m)
  },
  {
    name: rnd01BCxScTBYVYMeta?.name ?? "rnd-rnd01",
    path: rnd01BCxScTBYVYMeta?.path ?? "/rnd/rnd01",
    meta: rnd01BCxScTBYVYMeta || {},
    alias: rnd01BCxScTBYVYMeta?.alias || [],
    redirect: rnd01BCxScTBYVYMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/rnd/rnd01.vue").then(m => m.default || m)
  },
  {
    name: rnd02YPabRHyC0eMeta?.name ?? "rnd-rnd02",
    path: rnd02YPabRHyC0eMeta?.path ?? "/rnd/rnd02",
    meta: rnd02YPabRHyC0eMeta || {},
    alias: rnd02YPabRHyC0eMeta?.alias || [],
    redirect: rnd02YPabRHyC0eMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/rnd/rnd02.vue").then(m => m.default || m)
  },
  {
    name: rnd03Z0u7QcYdGBMeta?.name ?? "rnd-rnd03",
    path: rnd03Z0u7QcYdGBMeta?.path ?? "/rnd/rnd03",
    meta: rnd03Z0u7QcYdGBMeta || {},
    alias: rnd03Z0u7QcYdGBMeta?.alias || [],
    redirect: rnd03Z0u7QcYdGBMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/rnd/rnd03.vue").then(m => m.default || m)
  },
  {
    name: rnd04rYx09XeYiSMeta?.name ?? "rnd-rnd04",
    path: rnd04rYx09XeYiSMeta?.path ?? "/rnd/rnd04",
    meta: rnd04rYx09XeYiSMeta || {},
    alias: rnd04rYx09XeYiSMeta?.alias || [],
    redirect: rnd04rYx09XeYiSMeta?.redirect || undefined,
    component: () => import("/app/src/client/pages/rnd/rnd04.vue").then(m => m.default || m)
  }
]